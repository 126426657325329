@import '../../scss/variables';


.LoadingDots {

&__container {

    justify-content: center;
    align-items: center;

    span {
        display: inline-block;
        background-color: $shade-50;
        border-radius: 20px;
        width: 10px;
        height: 10px;
        margin: 5px;
        @include prefix (transform, scale(0));
        @include prefix (animation-name, loading);
        @include prefix (animation-duration, 2.5s);
        @include prefix (animation-iteration-count, infinite);
    }

    &--dot-02 {
        @include prefix (animation-delay, 300ms);
    }

    &--dot-03 {
        @include prefix (animation-delay, 600ms);
    }

    &--dot-04 {
        @include prefix (animation-delay, 900ms);
    }
    
    &--dot-05 {
        @include prefix (animation-delay, 1200ms);
    }
    

}

}