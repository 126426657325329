// forms
input:not([type="radio"]):not([type="checkbox"]):not(.custom),
textarea {

    @include prefix (appearance, none);
    font-size: 11pt;
    border: 2px solid $shade-05;
    border-radius: 7px;
    width: 100%;
    padding: 15px;

    &:focus {
        outline: none;
        background-color: white;
        border-color: $c-primary;
        box-shadow: 0 0 10px rgba($c-primary, 0.25);
    }

    &.success {

        border-color: $c-green;

        &:focus {
            box-shadow: 0 0 10px rgba($c-green, 0.50);
        }

    }

    &.error {

        border-color: $c-red;

        &:focus {
            box-shadow: 0 0 10px rgba($c-red, 0.50);
        }
        
    }

    &::placeholder,
    &::-webkit-input-placeholder {
        color: $shade-30
    }

}

input {
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }

}

select {

    appearance: none;
    font-size: 11pt;
    border: 2px solid $shade-05;
    border-radius: 7px;
    width: 100%;
    padding: 15px;

    &:focus {
        outline: none;
        background-color: white;
        border: 2px solid $c-primary;
        box-shadow: 0 0 10px rgba($c-primary, 0.25);
    }

    &.success {

        border-color: $c-green;

        &:focus {
            box-shadow: 0 0 10px rgba($c-green, 0.50);
        }

    }

    &.error {
        border-color: $c-red;

        &:focus {
            box-shadow: 0 0 10px rgba($c-red, 0.50);
        }

    }

}


p,
span {

    &.error {
        color: $c-highRisk;
        font-size: 9pt;
        font-weight: $f-medium;
    }

}

svg {

    &.error {
        fill: $c-highRisk;
        @include wh (8pt);
        @include prefix (transform, scale(1.5) translateY(0.5px));
        margin-left: 2px;
        margin-right: 5px;;
    }

}