@import '../../scss/variables';


.MenuLink {

&__container {

    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    svg {
        @include wh (9pt);
        margin-left: 5px;
        @include prefix (transform, translateY(1pt));
    }

}

&__link {

    color: $shade-80;
    font-size: 10pt;
    font-weight: $f-bold;
    text-decoration: none;
    margin: 0 30px;
    cursor: pointer;

    &:hover {

        color: $c-primary;

        svg {
            fill: $c-primary;
        }

    }

}

&__menu {

    &--container {
        position: absolute;
        top: 100%;
    }
    
    &--bg {
        background-color: $c-white;
        border-radius: 20px;
        box-shadow: $rised-shadow;
        min-width: 650px;
    }


}

&__item {

    color: initial;
    text-decoration: none;
    height: fit-content;

    &:hover {
        background-color: $c-white;
        border-radius: 10px;
        box-shadow: $flat-shadow;
        cursor: pointer;
    }

}

&__veqtor {

    width: fit-content;

    svg {
        fill: $c-primary;
        @include wh (25px);
        margin: 0;
    }

}

&__info {

    width: calc(100% - 25px);

    &--title {
        font-size: 10pt;
        font-weight: $f-bold;
        margin-bottom: 5px;
    }

    &--description {
        color: $shade-50;
        font-size: 8pt;
    }

}

}