@import '../../scss/variables';

.Multibutton {

&__scroll {
    max-width: 100%;
    overflow-x: scroll;
    // padding-right: 20px;
}

&__bg {

    background-color: $shade-05;
    border-radius: $btn-radius;
    width: fit-content;
    padding: 0;
    margin: 0 20px;
    white-space: nowrap;

    button {
        font-size: 9pt;
        padding-top: 7px;
        padding-bottom: 7px;
        margin: 0;
    }

}

}