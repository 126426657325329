@import '../../scss/variables';


.HelpSearch {

&__container {

    input {

        background-color: $shade-05;
        padding: 25px;

        &.active {
            background-color: initial;
        }

    }
    
}

}