@import '../../scss/variables';


.PressPage {

&__title {

    p {
        font-size: calc(min(16pt, 4.5vw));
    }

}

&__link-bar {

    &--link {
        color: $c-black;
        font-weight: $f-bold;
    }

}

&__news {

    &-top {

        @include media-xs {
            overflow: scroll;
        }

        &--container {
            width: fit-content;
            @include media-xs {
                min-width: calc(min(250%, 1000px));
            }
        }

    }

    &-archive {

        h3 {
            font-size: calc(min(30pt, 7vw));
        }

    }

}

}