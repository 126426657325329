@import '../../scss/variables';


@include keyframes-prefixes (glowing-cta) {
    0% {
        box-shadow: 0 0 0 0 rgba($c-primary, 1);
    }

    100% {
        box-shadow: 0 0 0 40px rgba($c-primary, 0);
    }
}


.FooterCta {

&__container { 

    color: $c-white;
    background-color: $c-black;

    h2 {
        font-size: calc(min(9.5vw, 45pt));
    }

    .btn {
        font-size: 12pt;
        border-radius: 100px;
        box-shadow: none;
        min-width: 250px;
        padding: 40px;
        @include prefix (animation-name, glowing-cta);
        @include prefix (animation-duration, 3000ms);
        @include prefix (animation-iteration-count, infinite);
    }
    
}

}
