@import '../../scss/variables';


.HistoryPage {

&__title {

    p {
        font-size: 16pt;
    }
    
}

&__today {
    justify-content: center;
    max-height: 170px;
    @include media-xs {
        max-width: calc(100% - 1px);
        max-height: 95px;
        justify-content: flex-end;
    }

    &>span {

        display: flex;
        color: $c-white;
        font-size: 40px;
        font-weight: $f-ultra;
        background-color: $c-black;
        border-radius: 50px;
        align-items: center;
        align-content: center;
        justify-content: center;
        @include wh (150px);
        @include prefix (transform, rotate(30deg));
        @include media-xs {
            font-size: 25px;
            border-radius: 30px;
            @include wh (90px);
        }

        span {
            @include prefix (transform, rotate(-30deg));
        }

    }

}

&__event-line {

    @mixin left {
        border-right: 3px solid $shade-05;
        border-leftt: none;
        align-items: center;
        align-content: center;
        justify-content: flex-end;
        @include media-xs {
            width: calc(100% - 55px);
        }
    }

    @mixin right {
        border-right: none;
        border-left: 3px solid $shade-05;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        @include media-xs {
            width: 55px;
        }
    }

    &--left {

        @include left;

        &.interchange {
            @include media-xs {
                @include right;
                order: 2;
            }
        }
    }

    &--right {

        @include right;

        &.interchange {
            @include media-xs {
                @include left;
                order: 1;
            }
        }
    }

    &--description {

        border-radius: 20px;
        padding: 20px;

        &.top {
            border-radius: 20px 20px 0 0;
        }

        &.middle {
            border-radius: none;
        }

        &.bottom {
            border-radius: 0 0 20px 20px;
        }

        h5 {
            color: $c-black;
            font-size: 16pt;
            font-weight: $f-black;
            margin-bottom: 10px;
        }

        p {
            
            font-size: 9pt;
            line-height: 1.8;
            margin: 5px 0;

            strong {
                font-weight: $f-black;
            }

            &.warning {
                color: $c-highRisk;
                margin-top: 10px;
            }

        }

    }

    &--image {

        border: 1px solid $shade-10;
        border-radius: 15px;
        height: fit-content;
        padding: 30px;
        margin-top: 15px;
        align-items: center;
        align-content: center;
        justify-content: center;

        .logo-ethics {

            align-items: center;
            align-content: center;

            img {
                max-height: 50px;
            }

            span {
                color: $shade-50;
                font-size: 8pt;
            }

        }

        .logo-qibo {

            align-items: center;
            align-content: center;

            img {
                max-height: 40px;
            }

            span {
                color: $shade-50;
                font-size: 8pt;
            }

        }

        .logo-ethics-old {
            max-height: 60px;
        }

        .logo-honestidad {
            max-height: 60px;
        }

    }

    @mixin date {
        display: flex;
        color: $c-white;
        font-size: 11pt;
        font-weight: $f-ultra;
        background-color: $c-primary;
        border-radius: 100px;
        @include wh (60px);
        align-items: center;
        align-content: center;
        justify-content: center;
        z-index: 1;
    }

    $date-margin: calc((15px + 3px + 30px) * -1);

    &--date-left {

        @include date;
        margin-right: $date-margin;
        margin-left: 0;

        &.interchange {
            @include media-xs {
                margin-right: 0;
                margin-left: $date-margin;
            }
        }

    }

    &--date-right {

        @include date;
        margin-right: 0;
        margin-left: $date-margin;

        &.interchange {
            @include media-xs {
                margin-right: $date-margin;
                margin-left: 0;
            }
        }

    }

}

}