// Text
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

*:not(pre) {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 50pt;            
    font-weight: $f-ultra;
    @include media-xs {
        font-size: 30pt;
    }
}

h2 {
    font-size: 40pt;
    font-weight: $f-black;           
}

h3 {
    font-size: 30pt;
    font-weight: $f-black;           
}

h4 {
    margin: 0;
    font-weight: $f-black;
}

h5 {
    color: $shade-50;
    margin: 0;
}

a {
    color: $shade-50;
    font-weight: $f-medium;
}

span {
    font-weight: inherit;
}

sup {
    all: initial;
    color: inherit;
    font-weight: inherit;
    font-size: 0.4em;
    vertical-align: super;
}

.txt--primary {
    color: $c-primary;
}

.txt--accent {

    position: relative;
    // color: $c-primary;

    &::before {
        position: absolute;
        display: inline-block;
        content: "";
        border-bottom: 0.2em solid $c-primary;
        width: 100%;
        height: 100%;
        bottom: -0.05em;
        z-index: -1;
    }

    &.accent--yellow {

        &::before {
            border-bottom-color: $c-yellow;
        }

    }
    
}

.p--gray {
    color: $shade-80;
    text-align: center;
    font-size: 10pt;
    font-weight: $f-medium;
}

.p--description {
    color: $shade-70;
    line-height: 2;
}

.p--identifier {

    display: inline-block;
    font-size: 10pt;
    font-weight: $f-bold;
    letter-spacing: 1.5pt;

    &.left {

        text-align: left;

        &::before {
            content: "";
            display: inline-block;
            border-top: 1px solid $c-black;
            width: 40px;
            margin-right: 10px;
            margin-bottom: 2px;
            vertical-align: middle;
        }

    }

    &.center {
        text-align: center;
    }

    &.right {

        text-align: right;

        &::after {
            content: "";
            display: inline-block;
            border-top: 1px solid $c-black;
            width: 40px;
            margin-bottom: 2px;
            margin-left: 10px;
            vertical-align: middle;
        }

    }

    @each $name, $color in $colors-list {
        &.#{$name} {
            color: $color;
            &.left {
                &::before {
                    border-color: $color;
                }
            }
            &.right {
                &::after {
                    border-color: $color;
                }
            }
        }
    }

}

.ls1-5 {
    line-height: 1.5;
}

.ls1-8 {
    line-height: 1.8;
}

.ls2 {
    line-height: 2;
}

.taLeft {
    text-align: left;
}

.taCenter {
    text-align: center;
}

.taRight {
    text-align: right;
}

.taJustify {
    text-align: justify;
}

.font--regular {
    font-weight: $f-regular;
}

.font--medium {
    font-weight: $f-medium;
}

.font--semibold {
    font-weight: $f-semibold;
}

.font--bold {
    font-weight: $f-bold;
}

.font--black {
    font-weight: $f-black;
}

.font--ultra {
    font-weight: $f-ultra;
}


.legal {
    color: $shade-50;
    font-size: 7pt;
    margin: 5px 0;
}

.highlight {

    @mixin highlight () {
        border-radius: 10px;
        padding: 10px $padding-x;
    }
    
    &HighRisk {
        @include highlight();
        color: $c-highRisk;
        background-color: lighten($c-highRisk, 43%);
    }

    &MedRisk {
        @include highlight();
        color: $c-yellow-dark;
        background-color: lighten($c-medRisk, 45%);
    }

    &LowRisk {
        @include highlight();
        color: $c-green-dark;
        background-color: lighten($c-lowRisk, 43%);
    }

    &Primary {
        @include highlight();
        color: $c-primary-dark;
        background-color: lighten($c-primary, 47%);
    }

}