@import '../../scss/variables';


.HelpCenterPage {

&__title {

    h1 {
        text-align: center;
    }

}

&__section-title {
    font-size: 25pt;
    font-weight: $f-black;
    padding: 0 15px;
}

&__expert {

    p {
        font-size: 24pt;
        font-weight: $f-bold;
        text-align: center;
        @include media-xs {
            text-align: left;
        }
    }

}

}