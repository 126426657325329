@import '../../scss/variables';


.RatingCard {

&__container {
    border-left: 1px solid $shade-05;
    height: 100%;
}

&__stars {
        
    svg {

        fill: $shade-05;
        @include wh (25px);
        margin-right: 7px;

        &.active {
            fill: $c-yellow;
        }

    }


}

&__quote {
    height: fit-content;
    flex-grow: 1;
}

&__name {

    p {

        margin: 0;

        &:nth-child(1) {
            font-size: 14pt;
            font-weight: $f-ultra;
        }

        &:nth-child(2) {
            color: $c-primary;
            font-size: 10pt;
            font-weight: $f-black;
            margin-top: 5px;
        }

    }

}

}