@import '../../scss/variables';


.DeliveryStatus {

&__container {

    @include center ();

    svg {
        @include wh (50px);
    }

    p:not(.DeliveryStatus__title) {
        color: $shade-50;
        font-size: 10pt;
        text-align: center;
        margin: 5px 0;
    }

}

&__title {
    color: $c-primary;
    font-size: 20pt;
    font-weight: $f-black;
    text-align: center;
}

}