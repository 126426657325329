@import '../../scss/variables';


.HomePage {

&__title {

    &--text {

        @include media-xs {
            order: 2;
        }

        h1 {

            font-size: calc(min(10vw, 45pt));

            span {
                // color: $c-highRisk;
                font-size: 1.3em;
            }

        }

        p {
            font-size: 14pt;
            line-height: 1.8;
            @include media-xs {
                font-size: 12pt;
            }
        }

        .btn {
            font-size: 12pt;
            font-weight: $f-black;
            padding: 20px 30px;
            @include media-xs {
                margin: 10px 0 50px;
                width: calc(100% + 30px);
            }
        }
        
    }

    &--image {

        img {
            max-width: 80%;
            max-height: 600px;
        }

        @include media-xs {
            order: 1;
        }

    }

}

&__media {

    background-color: $shade-02;

    &--title {

        p {
            color: $shade-15;
            font-size: 10pt;
            font-weight: $f-bold;
        }

    }

    &--logos {

        @include media-xs {
            flex-direction: column;
        }
        @include media-s {
            flex-direction: column;
        }

        svg {

            fill: $shade-20;
            width: 130px;
            height: 60px;
            @include media-xs {
                margin: 10px 0;
            }
            @include media-s {
                margin: 10px 0;
            }

            &.darker {
                fill: $shade-30;
            }

        }

    }

}

&__efficacy {

    &--info {
        @include media-xs {
            order: 1;
        }
    }

    &--graphs {

        @include media-xs {
            order: 2;
        }
    
        &-container {
            position: relative;
            height: fit-content;   
        }
    }

    &--vs {
        position: absolute;
        display: flex;
        color: $c-white;
        font-size: 16pt;
        font-weight: $f-black;
        background-color: $c-black;
        border-radius: 100px;
        @include wh (50px);
        @include center ();
        top: 100px;
        left: calc(50% - 25px);
    }

    &--chooser {

        &-item {

            cursor: pointer;
            
            span {
    
                display: inline-flex;
                color: $shade-70;
                font-size: 10pt;
                margin: 10px 0;
                vertical-align: middle;
    
                &::before {
                    content: "";
                    display: inline-flex;
                    border: 2px solid $shade-10;
                    border-radius: 100px;
                    @include wh (12px);
                    margin: auto 10px;
                    vertical-align: middle;
                }
    
            }

            &:hover {

                span {

                    &::before {
                        background-color: $c-primary;
                        border-color: $c-primary-light;
                    }

                }
            }

            &.active {

                span {

                    &::before {
                        background-color: $c-primary;
                        border-color: $c-white;
                        box-shadow: 0 0 0 2px $c-primary;
                    }

                }

            }

        }

    }

}

&__speed {

    &--legend {

        &-record {
            font-size: 10pt;
            margin-right: 1%;
        }

        &-Qibo {
            font-size: 12pt;
            font-weight: $f-black;
            margin-right: 56%;
        }

    }

    &--graph {

        &-bg {
            display: flex;
            background-color: $shade-05;
            border-radius: 100px;
            width: 100%;
            height: 10px;
            margin-top: 5px;
        }

        &-record,
        &-Qibo {
            display: flex;
            background-color: $c-black;
            border-radius: 100px;
            width: 100%;
            height: 100%;
        }

        &-Qibo {
            background-color: $c-primary;
            width: 44%;
        }

    }

}

}
