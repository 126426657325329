@import '../../scss/variables';


.NewsCard {

&__card {
    // background-color: $shade-01;
    border-radius: 20px;
    box-shadow: $flat-shadow;
}

&__image {
    background-color: $c-primary;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px 20px 0 0;
    height: 200px;
}

&__content {
    height: fit-content;
    flex-grow: 1;
}

&__logo {

    img {
        max-width: 100%;
        max-height: 30px;
        margin: 0;
    }
}

&__headline {
    font-weight: $f-bold;
    font-size: 14pt;
    @include media-s {
        font-size: 12pt;
    }
    @include media-xs {
        font-size: 12pt;
    }
}

&__date {

    span {
        color: $shade-50;
        font-size: 8pt;
    }

}

&__btn {

    height: fit-content;
    margin-top: auto;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;

    a {

        display: flex;
        margin: 0;
        align-items: center;
        align-content: center;

        svg {
            @include wh (10pt);
            margin-left: 10px;
            @include prefix (transform, scale(1.2));
        }

    }

}

}