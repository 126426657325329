@import '../../scss/variables';


.ContactPage {

&__title {

    h1 {
        font-size: calc(min(11vw, 40pt));
        font-weight: $f-ultra;
        margin: 0;
        @include media-m {
            font-size: 34pt;
        }
    }

}

&__chooser {

    &--item {

        display: flex;
        font-size: 10pt;
        font-weight: $f-bold;
        border-radius: 10px;
        width: 95%;
        padding: 15px;
        margin: 5px 0;
        align-items: center;
        align-content: center;
        cursor: pointer;

        span {
            display: inline-block;
            width: calc(100% - 13pt - 10px);
        }

        svg {
            fill: $shade-20;
            @include wh (13pt);
            margin-left: auto;
        }

        &:hover {
            background-color: $shade-05;
        }

        &.active {

            color: $c-white;
            background-color: $c-primary;

            svg {
                fill: $c-white;
            }

        }

    }

    &--scroller {

        overflow: scroll;
        height: 30px;

        &>span:nth-child(1) {
            margin-left: 15px;
        }
    }

    &--pill {

        display: inline-block;
        font-size: 10pt;
        font-weight: $f-bold;
        background-color: $shade-05;
        border-radius: 100px;
        width: fit-content;
        padding: 5px 20px;
        margin: 0 10px;
        cursor: pointer;

        &.active {
            color: $c-white;
            background-color: $c-primary;
        }

    }

}

&__phone {

    background-color: $shade-03;
    border-radius: 20px;
    @include media-xs {
        border-radius: 0;
    }

    &--message {
        font-size: 14pt;
        font-weight: $f-semibold;
        text-align: center;
        margin-left: 15px;
    }

    &--number {
        display: flex;
        color: $c-white;
        font-size: 14pt;
        font-weight: $f-black;
        background-color: $c-black;
        border-radius: 100px;
        width: fit-content;
        padding: 20px 30px;
        margin: 20px 0 40px;
    }

}

// &__form {
//     background-color: $shade-01;
//     box-shadow: $flat-shadow;
//     border-radius: 10px;
// }

}