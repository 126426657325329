@import '../../scss/variables';


.EfficacyCard {

&__container {

    border: 2px solid $c-black;
    border-radius: 20px;
    height: 100%;

}

&__title {

    height: 50px;
    margin: 30px 0 15px;
    @include center ();

    p {

        font-size: 16pt;
        font-weight: $f-black;
        text-align: center;
        margin: 0;

        &.Qibo {
            font-size: 24pt;
        }

    }


}

&__params {

    &--row {
        
        padding: 7px $padding-x;
        align-items: center;
        align-content: center;

        span {

            &:nth-child(1) {
                display: flex;
                background-color: $shade-05;
                border-radius: 100px;
                @include wh (10px);
                margin-right: 10px;
            }

            &:nth-child(2) {
                color: $shade-10;
                font-size: 10pt;
            }

        }

        &.active {

            span {

                &:nth-child(1) {
                    
                    background-color: $c-primary;

                    &.yellow {
                        background-color: $c-yellow;
                    }

                    &.red {
                        background-color: $c-red;
                    }

                    &.black {
                        background-color: $c-black;
                    }

                }
    
                &:nth-child(2) {
                    color: $c-black;
                }

            }

        }

    }

}

&__objectiveness {

    position: relative;

    &::before {
        position: absolute;
        content: "";
        display: flex;
        background-color: $c-black;
        border-radius: 100px;
        width: calc(50% - 15px);
        height: calc(100% - 30px);
        top: 15px;
        left: 15px;
        @include prefix (transition, (left 500ms, background-color 500ms));
        z-index: -1;
    }

    span {

        display: flex;
        font-size: 10pt;
        border: 1px solid $c-black;
        padding: 3px 10px;
        width: 50%;
        @include center ();
        @include prefix (transition, color 300ms);

        &:nth-child(1) {
            color: $c-white;
            border-radius: 100px 0 0 100px;
            border-right: none;
        }

        &:nth-child(2) {
            color: $c-black;
            border-radius: 0 100px 100px 0;
            border-left: none;
        }

    }

    &.subjective {

        &::before {
            left: 50%;
        }

        span {
            
            &:nth-child(1) {
                color: $c-black;
            }
    
            &:nth-child(2) {
                color: $c-white;
            }

        }
        
    }

}

}