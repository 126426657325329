@import '../../scss/variables';


.TermsPage {

&__section {

    &--title {

        h3 {
            margin: 0;
        }

    }

    &--text {

        p,
        li {

            font-size: 11pt;
            font-weight: $f-medium;
            line-height: 1.8;

            strong {
                font-weight: $f-bold;
            }

        }

        ol {

            &.roman {
                list-style-type: upper-roman
            }

            &.latin {
                list-style-type: lower-latin;
            }

        }

    }

}

}