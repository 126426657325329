@import '../../scss/variables';


.SalesHomeForm {

&__field {

    &--container {

        align-items: flex-start;
        align-content: flex-start;

        &.error {
            border-bottom: 2px solid $c-highRisk;
        }

    }

}

&__label {
    margin: 0;
}

&__hint {
    color: $shade-50;
    font-size: 8pt;
    margin: 0;
    margin-top: 5px;
}

&__delivery-message {

    @include center ();

    svg {
        @include wh (50px);
        margin-bottom: 10px;
    }

    p {
        color: $shade-50;
    }

}

}