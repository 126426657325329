@import '../../scss/variables';


.Footer {

&__container {
    border-top: 1px solid $shade-05;
}

&__company {

    &--logo {
        height: 30px;
    }

}

&__sections {

    &--title {
        font-size: 14pt;
        font-weight: $f-black;
        margin-top: 0;
    }

    &--link {

        color: $shade-80;
        font-weight: $f-semibold;
        text-decoration: none;
        margin: 10px 0;

        &:hover {
            color: $shade-40;
        }

    }

}

&__legal {

    &--conatiner {
        border-top: 1px solid $shade-05;
    }

    &--link {
        color: $shade-50;
        font-size: 8pt;
        font-weight: $f-medium;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    &--text {
        color: $shade-50;
        font-size: 8pt;
        font-weight: $f-medium;
        margin: 0;
    }

}

}