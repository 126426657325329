@import '../../scss/variables';


.NewsRow {

&__container {

    border-top: 1px solid $shade-20;

    &.first {
        border: none;
    }

}

&__brand {
    font-size: 14pt;
    font-weight: $f-bold;
    @include media-xs {
        font-size: 3.5vw;
    }
}

&__date {
    color: $shade-50;
    font-size: 8pt;
}

&__headline {

    &--link {

        color: $c-black;
        font-size: calc(min(18pt, 4vw));
        font-weight: $f-bold;
        text-decoration: none;

        &:hover {
            color: $shade-70;
            text-decoration: underline;
        }

    }

}

&__tags {

    span {
        display: inline-block;
        font-size: 8pt;
        font-weight: $f-bold;
        border: 2px solid $c-primary;
        border-radius: 100px;
        padding: 5px 10px;
        margin: 10px 10px 0 0;
    }

}

}