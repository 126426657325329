// Buttons
$colors-for-buttons:
        "pink" $c-pink white white,
        "purple" $c-purple white white,
        "blue" $c-blue white white,
        "candy" $c-candy black black,
        "mint" $c-mint black black,
        "green" $c-green white black,
        "yellow" $c-yellow black black,
        "orange" $c-orange white white,
        "red" $c-red white white,
        "primary" $c-primary white white,
        "lowRisk" $c-lowRisk white black,
        "medRisk" $c-medRisk black black,
        "highRisk" $c-highRisk white white,
        "ghost" $shade-05 black black,
        "clear" initial black black;

.btn {
    color: $c-white;
    text-align: center;
    text-decoration: none;
    font-size: 10pt;
    font-weight: $f-bold;
    background-color: $c-black;
    border: none;
    border-radius: $btn-radius;
    padding: 10px $padding-x;
    margin: 15px 0 10px;
    cursor: pointer;
    @include prefix (transition, (transform 0.5s, box-shadow 0.6s, color 0.3s, background-color 0.3s));

    &:hover {

        box-shadow: inset 0 0 200px rgba($c-white, 0.1);
        
        &:focus {
            box-shadow: 0 0 0 5px $shade-10, inset 0 0 200px rgba($c-white, 0.1);
        }

    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 5px $shade-10;
        @include prefix (transition, (transform 0.5s, box-shadow 0.6s));
    }

    &.btn--stroke {
        box-sizing: border-box;
        color: $c-black;
        background-color: initial;
        border: 2px solid $c-black;
        padding: 8px ($padding-x - 2px);

        &:hover {
            color: $c-white;
            background-color: $c-black;
        }

        &.active {
            color: $c-white;
            background-color: $c-black;
        }
    }

    @each $color, $hex, $text-color, $hover-color in $colors-for-buttons {
        &--#{$color} {
            color: $text-color;
            background-color: #{$hex};

            &:hover {
                box-shadow: inset 0 0 200px rgba($hover-color, 0.1);
            }
        }
    }

    @each $color, $hex, $text-color, $hover-color in $colors-for-buttons {
        @if $color != clear {
            &--#{$color}.btn--stroke {
                color: $hex;
                background-color: initial;
                border-color: #{$hex};

                &:hover {
                    color: $text-color;
                    background-color: $hex;
                    box-shadow: none;
                }

                &.active {
                    color: $text-color;
                    background-color: $hex;
                    box-shadow: none;
                }
            }
        }
    }

    &--ghost:active,
    &--ghost.active {

        color: white;
        background-color: black;

        svg {
            fill: $c-white;
        }

    }

    &--clear.active,
    &--clear.active:hover {
        color: $c-primary;
        background-color: lighten($c-primary, 47.5%);
        box-shadow: initial;
    }
    
    &--grow:hover {
        @media (any-hover: hover) {
            @include prefix (transform, scale(1.03));
        }
    }
    
    &--grow:active{
        @media (any-hover: hover) {
            @include prefix (transform, scale(0.95));
            @include prefix (transition, transform 0.1s);
        }
    }

    &--block {
        width: 100%;
    }

    &--disabled {

        color: $shade-20;
        background-color: $shade-05;
        

        &:hover {
            box-shadow: none;
            transform: none;
            cursor: not-allowed;;
        }

        &:active,
        &.active {
            color: $shade-20;
            background-color: $shade-05;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

    }

    &--icon {

        border-radius: 100px;
        padding: 0;
        margin: 0;
    
        svg {
            fill: black;
            vertical-align: middle;
        }
    
        &:active {
    
            svg {
                fill: white;
            }
            
        }

        &-small {

            @include wh (30px);

            svg {
                @include wh (12pt)
            }

        }

        &-only {

            background-color: initial;
            padding: 0;
            margin: 0;

            svg {
                fill: $shade-20;
                @include wh (30px)
            }

            &:active,
            &.active {

                svg {
                    fill: $c-primary;
                }

            }

            &-small {

                @extend .btn--icon-only;

                svg {
                    @include wh (20px)
                }

            }

        }

    }

}

.href {

    color: black;
    font-size: 10pt;
    text-decoration: underline;
    cursor: pointer;

    @each $color, $hex, $text-color, $hover-color in $colors-for-buttons {
        @if $color == clear {
            &--#{$color} {
                color: inherit;
            }
        } @else if $color == ghost {
            &--#{$color} {
                color: $shade-50
            }
        } @else {
            &--#{$color} {
                color: $hex;
            }
        }
    }

}

.switch {

    background-color: $shade-05;
    border: none;
    border-radius: 20px;
    width: 40px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    @include prefix (transition, background-color 0.3s);
    position: relative;

    &::after {
        content: "";
        display: inline-block;
        border-radius: 20px;
        background-color: $shade-10;
        box-shadow: 0 1px 3px rgba($shade-80, 0.4);
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        @include prefix (transform, translateX(-20px) scale(1.2));
        @include prefix (transition, (transform 0.3s, background-color 0.3s));
    }

    &.active {

        background-color: $shade-80;

        &::after {
            background-color: black;
            @include prefix (transform, translateX(0px) scale(1.2));
        }

    }

    &:focus {
        outline-style: dotted;
    }

    @each $color, $hex, $text-color, $hover-color in $colors-for-buttons {
        @if $color != clear {
            &--#{$color}.active {
                background-color: lighten($hex,10%);

                &::after {
                    background-color: #{$hex};
                    @include prefix (transform, translateX(0px) scale(1.2));
                } 
            }
        }
    }

    &--ghost.active {

        background-color: $shade-25;

        &::after {
            background-color: $shade-30;
        }

    }

    &--extra-small {
        
        width: 20px;
        height: 13px;

        &::after {
            width: 10px;
            height: 10px;
            @include prefix (transform, translate(-10px, 1px) scale(1.2));
        }

        &.active {

            &::after {
                @include prefix (transform, translate(0px, 1px) scale(1.2));
            }

        }

    }
    
}