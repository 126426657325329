@import '../../scss/variables';


.PrivacyPage {

&__section {

    &--title {

        h3 {
            margin: 0;
        }

    }

    &--text {

        p,
        li,
        th,
        td {

            font-size: 11pt;
            font-weight: $f-medium;
            line-height: 1.8;

            strong {
                font-weight: $f-bold;
            }

        }

        ol {

            &.roman {
                list-style-type: upper-roman
            }

            &.latin {
                list-style-type: lower-latin;
            }

        }

        table {

            border-collapse: collapse;
            table-layout: fixed;
            width: 100%;

            th,
            td {
                text-align: center;
                border: 1px solid $shade-20;
                padding: $padding-y $padding-x;
            }

            th {
                color: $c-primary;
                font-weight: $f-ultra;
            }

        }

    }

}

}