@import '../../scss/variables';


.Select {

// &__listener {
    // position: absolute;
    // background-color: blue;
    // width: 100vw;
    // height: 100vh;
    // top: 0;
    // left: 0;
    // z-index: -10;
// }

&__container {
    position: relative;
}

&__active-value {

    font-size: 11pt;
    background-color: $c-white;
    border: 2px solid $shade-05;
    border-radius: 7px;
    padding: 15px;
    cursor: pointer;

    &.placeholder {
        color: $shade-30;
    }

    &.success {
        border-color: $c-lowRisk;
    }

    &.error {
        border-color: $c-highRisk;
    }

    &.disabled {
        color: $shade-20;
        background-color: $shade-05;
        cursor: not-allowed;
    }

}

&__iqon {
    fill: $shade-10;
    @include wh (14pt);
    position: absolute;
    top: calc(50% - 7pt);
    right: 15px;
    cursor: pointer;
}

&__options {

    position: absolute;
    background-color: white;
    border-radius: 10px;
    box-shadow: $rised-shadow;
    padding: 5px;
    top: 0;
    overflow-y: scroll;
    z-index: 1;

    span {

        display: block;
        font-size: 10pt;
        border-radius: 10px;
        width: 100%;
        padding: 7px $padding-x;
        cursor: pointer;

        small {
            color: $shade-50;
        }

        &.grouped {
            width: calc(100% - 15px);
            margin-left: 15px;
        }

        &.active {

            color: $shade-50;
            background-color: $shade-05;

            small {
                color: $shade-30;
            }

        }

        // &:nth-child(1) {
        //     padding-top: 10px;
        // }

        // &:nth-last-child(1) {
        //     padding-bottom: 10px;
        // }

        &:hover {

            color: white;
            background-color: $c-primary;

            small {
                color: lighten($c-primary, 25%);
            }

        }

        &.group {

            color: $shade-50;
            font-size: 9pt;
            border-bottom: 1px solid $shade-05;
            border-radius: 0;
            cursor: initial;

            &:hover {
                background-color: initial;
            }

        }

    }

}

}