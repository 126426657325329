@import '../../scss/variables';


.Header {

    background-color: $c-white;
    // border-bottom: 1px solid $shade-10;
    // box-shadow: $rised-shadow;
    height: 90px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 50;

// &__logo {
//     max-width: fit-content;
//     padding: 0 40px 0 calc(7% - 17.1615px);

//     img {
//         height: 85px;
//         margin-top: 20px;
//         // margin-left: calc(7% - 17.1615px);
//         z-index: 1;
//     }

// }

&__logo {
    
    max-width: fit-content;
    padding: 0 40px 0 7%;
    margin-top: 15px;
    justify-content: center;
    z-index: 1001;

    img {

        height: 55px;
        z-index: 1;

        @include media-xs {
            height: 40px;
        }

    }

}

&__links {

    flex-grow: 1;
    max-height: 90px;

    &--hamburger {

        position: relative;
        @include wh (40px);
        @include center ();
        cursor: pointer;
        z-index: 1001;

        span {

            position: absolute;
            border-top: 3px solid $c-black;
            width: 25px;
            top: calc(50% - 2px);
            @include prefix (transition, transform 0.3s);
            @include prefix (transition, top 0.6s);
            @include prefix (transition-delay, top 0.3s);
            
            &.first {

                top: calc(50% - 7px);

                &.active {
                    @include prefix (transform, rotate(-45deg));
                }

            }

            &.second {

                top: calc(50% + 3px);

                &.active {
                    @include prefix (transform, rotate(45deg));
                }

            }

            &.active {
                top: calc(50% - 2px);
                @include prefix (transition, top 0.3s);
                @include prefix (transition, transform 0.6s);
                @include prefix (transition-delay, transform 0.3s);
            }

        }

    }

    &--screen {

        position: absolute;
        background-color: $c-white;
        overflow: scroll;
        z-index: 1000;

        // .btn {
        //     width: calc(50% - 5px);
        // }

    }

}

}

// html:not([data-scroll="0"]) {
    
//     .Header {
//       box-shadow: $rised-shadow;
//     }
    
// }