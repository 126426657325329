@import '../../scss/variables';


.HousePage {

&__title {

    h1 {
        font-size: calc(min(9.5vw, 45pt));
        text-align: center;
        margin: 15px 0;
    }

    &--subtitle {
        font-weight: $f-medium;
        font-size: calc(min(4vw, 16pt));
        text-align: center;
    }
    
}

&__risk-list {

    span {
        display: flex;
        // color: $c-white;
        font-size: 10pt;
        font-weight: $f-bold;
        white-space: nowrap;
        // background-color: $shade-10;
        border: 2px solid $c-black;
        border-radius: 100px;
        padding: 5px 20px;
        margin: 10px;
        @include media-xs {
            font-size: 3vw;
            margin: 10px 1vw;
        }
    }

}

&__cta {

    .btn {
        font-size: 12pt;
        font-weight: $f-black;
    }

    &-footer {
        color: $c-white;
        background-color: $c-black;
    }

}

&__features {

    &--risk {

        &-level,
        &-topics,
        &-people {

            img {
                width: 70%;
                margin: auto;
            }

            @include media-xs {

                order: 2;

                img {
                    width: 90%;
                    margin: auto;
                }

            }

        }

    }

}

&__feature {
    margin: 100px 0;
}

}