// Animations
@include keyframes-prefixes (fade-in) {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@include keyframes-prefixes (fade-out) {
    0% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}

@include keyframes-prefixes (scale-in) {
    0% {
        @include prefix (transform, scale(0.9));
    }

    100% {
        @include prefix (transform, scale(1));
    }
}

@include keyframes-prefixes (enter-right) {
    0% {
        @include prefix (transform, translateX(100%));
    }

    100% {
        @include prefix (transform, translateX(0));
    }
}

@include keyframes-prefixes (leave-right) {
    0% {
        @include prefix (transform, translateX(0));
    }

    100% {
        @include prefix (transform, translateX(100%));
    }
}

@include keyframes-prefixes (enter-bottom) {
    0% {
        @include prefix (transform, translateY(100%));
    }

    100% {
        @include prefix (transform, translateY(0));
    }
}

@include keyframes-prefixes (leave-bottom) {
    0% {
        @include prefix (transform, translateY(0));
    }

    100% {
        @include prefix (transform, translateY(100%));
    }
}

@include keyframes-prefixes (loading) {
    0% {
        @include prefix (transform, scale(0));
    }
    
    35% {
        @include prefix (transform, scale(1));
    }

    70% {
        @include prefix (transform, scale(0));
    }

    100% {
        @include prefix (transform, scale(0));
    }
}




.fade-in {
    @include prefix (animation-name, fade-in);
    @include prefix (animation-duration, 400ms);
}

.fade-in--fast {
    @include prefix (animation-name, fade-in);
    @include prefix (animation-duration, 200ms);
}

.fade-in--super-fast {
    @include prefix (animation-name, fade-in);
    @include prefix (animation-duration, 50ms);
}


.fade-out {
    @include prefix (animation-name, fade-out);
    @include prefix (animation-duration, 400ms);
}

.fade-out--fast {
    @include prefix (animation-name, fade-out);
    @include prefix (animation-duration, 200ms);
}

.fade-out--super-fast {
    @include prefix (animation-name, fade-out);
    @include prefix (animation-duration, 50ms);
}


.scale-in {
    @include prefix (animation-name, scale-in);
    @include prefix (animation-duration, 400ms);
}

.scale-in--fast {
    @include prefix (animation-name, scale-in);
    @include prefix (animation-duration, 200ms);
}

.scale-in--super-fast {
    @include prefix (animation-name, scale-in);
    @include prefix (animation-duration, 50ms);
}


.enter-right {
    @include prefix (animation-name, enter-right);
    @include prefix (animation-duration, 400ms);
}

.enter-right--fast {
    @include prefix (animation-name, enter-right);
    @include prefix (animation-duration, 200ms);
}

.enter-right--super-fast {
    @include prefix (animation-name, enter-right);
    @include prefix (animation-duration, 50ms);
}


.leave-right {
    @include prefix (animation-name, leave-right);
    @include prefix (animation-duration, 400ms);
}

.leave-right--fast {
    @include prefix (animation-name, leave-right);
    @include prefix (animation-duration, 200ms);
}

.leave-right--super-fast {
    @include prefix (animation-name, leave-right);
    @include prefix (animation-duration, 50ms);
}


.enter-bottom {
    @include prefix (animation-name, enter-bottom);
    @include prefix (animation-duration, 400ms);
}

.enter-bottom--fast {
    @include prefix (animation-name, enter-bottom);
    @include prefix (animation-duration, 200ms);
}

.enter-bottom--super-fast {
    @include prefix (animation-name, enter-bottom);
    @include prefix (animation-duration, 50ms);
}


.leave-bottom {
    @include prefix (animation-name, leave-bottom);
    @include prefix (animation-duration, 400ms);
}

.leave-bottom--fast {
    @include prefix (animation-name, leave-bottom);
    @include prefix (animation-duration, 200ms);
}

.leave-bottom--super-fast {
    @include prefix (animation-name, leave-bottom);
    @include prefix (animation-duration, 50ms);
}
