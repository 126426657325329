@import '../../scss/variables';


.RankingRow {

&__container {

    border-radius: 10px;
    border: 1px solid $shade-20;
    margin: 5px 0;

    &:hover {
        @media (any-hover: hover) {
            border-color: $c-white;
            box-shadow: $rised-shadow;
        }
    }

}

&__title {

    color: $shade-50;
    text-align: center;
    font-size: 9pt;

    span {

        display: flex;
        border-radius: 5px;
        padding: 3px 10px;
        @include center ();
        cursor: pointer;
        
        svg {
            fill: $shade-50;
            @include wh (8pt);
            margin-left: 5px;
            margin-top: 2pt;
            @include prefix (transform, rotate(-90deg));
        }
        
        &.desc {
            
            svg {
                margin-top: 0;
                @include prefix (transform, rotate(90deg));
            }

        }
        
        &:hover {
            background-color: $shade-05;
        }

    }
}

&__rank {

    
    span {
        display: flex;
        color: $c-white;
        font-size: 14pt;
        font-weight: $f-black;
        background-color: $c-black;
        border-radius: 100px;
        @include wh (35px);
        @include center ();
    }

    &-previous {
        
        span {
            display: flex;
            color: $c-black;
            font-size: 10pt;
            border: 1.5px solid $c-black;
            border-radius: 5px;
            padding: 1px 5px;
            min-width: 40px;
            @include center ();
        }

    }

}

&__variance {

    span {
        color: $shade-20;
        font-size: 11pt;
        margin: 0 5px;
    }

    svg {
        fill: $shade-20;
        @include wh (11pt);
    }

    &.red {

        span {
            color: $c-red;
        }

        svg {
            fill: $c-red;
            margin-top: 3pt;
            @include prefix (transform, rotate(-90deg));
        }

    }

    &.green {

        span {
            color: $c-green;
        }

        svg {
            fill: $c-green;
            @include prefix (transform, rotate(90deg));
        }

    }

}

&__name {
    font-size: 13pt;
    @include media-xs {
        font-size: 11pt;
    }
}

}