

// Variables
$width-percentages: (
    "12": 100%,
    "11": 91.6666666666%,
    "10": 83.3333333333%,
    "09": 75%,
    "08": 66.6666666666%,
    "07": 58.3333333333%,
    "06": 50%,
    "05": 41.6666666666%,
    "04": 33.3333333333%,
    "03": 25%,
    "02": 16.6666666666%,
    "01": 8.3333333333%
);


// Mixins
@mixin flexbox ($direction) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: #{$direction};
    width: 100%;
}


// Styles
.container {
    @include flexbox (row);
    margin: 0 auto;
    flex-shrink: 0;
    max-width: 100%;
}

.column {
    @include flexbox (column);
}

.row {
    @include flexbox (row);
}

.container-flow {
    @include flexbox (row);
    margin: 0 auto;
    flex-shrink: 0;
}

.cFit {
    width: fit-content;
    padding: $padding-y $padding-x;
}


.c12s, .c12m, .c12l,
.c11s, .c11m, .c11l,
.c10s, .c10m, .c10l,
.c09s, .c09m, .c09l,
.c08s, .c08m, .c08l,
.c07s, .c07m, .c07l,
.c06s, .c06m, .c06l,
.c05s, .c05m, .c05l,
.c04s, .c04m, .c04l,
.c03s, .c03m, .c03l,
.c02s, .c02m, .c02l,
.c01s, .c01m, .c01l {width: 100%; padding: $padding-y $padding-x;}

@each $number, $percentage in $width-percentages {
    .c#{$number} {width: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
}

@each $number, $percentage in $width-percentages {
    .r#{$number} {height: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
}


// Extra small media
@media (max-width: $media-small - 1) {
}


// Small media
@media (min-width: $media-small) and (max-width: $media-medium - 1) {
    .container {
        width: 750px;
        max-width: 750px;
    }

    @each $number, $percentage in $width-percentages {
        .c#{$number}s {width: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
    }
}


// Medium media
@media (min-width: $media-medium) and (max-width: $media-large - 1) {
    .container {
        width: 970px;
        max-width: 970px;
    }

    @each $number, $percentage in $width-percentages {
        .c#{$number}s {width: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
    }

    @each $number, $percentage in $width-percentages {
        .c#{$number}m {width: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
    }
}


// Large media
@media (min-width: $media-large) {
    .container {
        width: 1170px;
        max-width: 1170px;
    }

    @each $number, $percentage in $width-percentages {
        .c#{$number}s {width: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
    }

    @each $number, $percentage in $width-percentages {
        .c#{$number}m {width: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
    }

    @each $number, $percentage in $width-percentages {
        .c#{$number}l {width: #{$percentage}; padding: #{$padding-y} #{$padding-x};}
    }
}