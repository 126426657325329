@import '../../scss/variables';


.MobileMenuLink {

&__link {

    display: flex;
    color: $shade-80;
    font-size: 10pt;
    font-weight: $f-bold;
    text-decoration: none;
    width: 100%;
    align-items: center;
    align-content: center;
    cursor: pointer;
    
    svg {
        @include wh (9pt);
        margin-left: auto;
        @include prefix (transform, translateY(1pt));
        @include prefix (transition, transform 0.5s);
        
    }

    &.active,
    &:active {

        color: $c-primary;

        svg {
            fill: $c-primary;
            @include prefix (transform, (translateY(1pt) rotate(180deg)));
        }

    }

}

&__menu {
    height: fit-content;
    overflow: hidden;
    @include prefix (transition, height 0.5s);
}

&__item {

    color: initial;
    text-decoration: none;
    height: fit-content;

    &:hover {
        background-color: $c-white;
        border-radius: 10px;
        box-shadow: $flat-shadow;
        cursor: pointer;
    }

}

&__veqtor {

    width: fit-content;

    svg {
        fill: $c-primary;
        @include wh (25px);
        margin: 0;
    }

}

&__info {

    width: calc(100% - 25px);

    &--title {
        font-size: 10pt;
        font-weight: $f-bold;
        margin-bottom: 5px;
    }

    &--description {
        color: $shade-50;
        font-size: 8pt;
    }

}

}