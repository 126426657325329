// Media
$media-small: 768px;
$media-medium: 992px;
$media-large: 1200px;


//  Text
$f-regular: 400;
$f-medium: 500;
$f-semibold: 600;
$f-bold: 700;
$f-black: 800;
$f-ultra: 900;

// Paddings & Margins
$padding-y: 15px;
$padding-x: 15px;


// Colors
$c-pink: #FF0DC0;
$c-purple: #A803FF;
$c-blue: #0364FF;
$c-candy: #58E6F4;
$c-mint: #60F2E3;
$c-green: #30DD4D;
$c-yellow: #FFD000;
$c-orange: #FF9100;
$c-red: #FF184F;
$c-white: white;
$c-black: black;

$c-pink-light: #D684FF;
$c-purple-light: #C277FC;
$c-blue-light: #49A5FE;
$c-candy-light: #B6FFFF;
$c-mint-light: #A7FCED;
$c-green-light: #65EA6B;
$c-yellow-light: #FFE552;
$c-orange-light: #FFBA4E;
$c-red-light: #FF6692;

$c-pink-dark: #660053;
$c-purple-dark: #330666;
$c-blue-dark: #003A66;
$c-candy-dark: #006663;
$c-mint-dark: #006652;
$c-green-dark: #186621;
$c-yellow-dark: #665200;
$c-orange-dark: #663800;
$c-red-dark: #66002A;

$c-primary: $c-blue;
$c-primary-light: $c-blue-light;
$c-primary-dark: $c-blue-dark;
$c-lowRisk-light: $c-green-light;
$c-lowRisk: $c-green;
$c-lowRisk-dark: $c-green-dark;
$c-medRisk-light: $c-yellow-light;
$c-medRisk: $c-yellow;
$c-medRisk-dark: $c-yellow-dark;
$c-highRisk-light: $c-red-light;
$c-highRisk: $c-red;
$c-highRisk-dark: $c-red-dark;

$shade-01: hsl(200deg, 10%, 99%);
$shade-02: hsl(200deg, 10%, 98%);
$shade-03: hsl(200deg, 10%, 97%);
$shade-04: hsl(200deg, 10%, 96%);
$shade-05: hsl(200deg, 10%, 95%);
$shade-10: hsl(200deg, 10%, 90%); $shade-15: hsl(200deg, 10%, 85%);
$shade-20: hsl(200deg, 10%, 80%); $shade-25: hsl(200deg, 10%, 75%);
$shade-30: hsl(200deg, 10%, 70%); $shade-35: hsl(200deg, 10%, 65%);
$shade-40: hsl(200deg, 10%, 60%); $shade-45: hsl(200deg, 10%, 55%);
$shade-50: hsl(200deg, 10%, 50%); $shade-55: hsl(200deg, 10%, 45%);
$shade-60: hsl(200deg, 10%, 40%); $shade-65: hsl(200deg, 10%, 35%);
$shade-70: hsl(200deg, 10%, 30%); $shade-75: hsl(200deg, 10%, 25%);
$shade-80: hsl(200deg, 10%, 20%); $shade-85: hsl(200deg, 10%, 15%);
$shade-90: hsl(200deg, 10%, 10%); $shade-95: hsl(200deg, 10%, 5%);

$bg-alpha: rgba($shade-05, 0.8);

@mixin watermark () {
    display: flex;
    color: $c-white;
    font-size: 8pt;
    text-align: center;
    background-color: rgba($shade-75, 0.75);
    border-radius: 10px;
    width: 70%;
    padding: 5px;
    align-items: center;
    align-content: center;
    justify-content: center;
    svg {
        fill: $c-white;
        @include wh (10pt);
    }
}

$colors-list:
    "pink" $c-pink,
    "purple" $c-purple,
    "blue" $c-blue,
    "candy" $c-candy,
    "mint" $c-mint,
    "green" $c-green,
    "yellow" $c-yellow,
    "orange" $c-orange,
    "red" $c-red,
    "black" $c-black,
    "primary" $c-primary,
    "shade" $shade-50;


// Shadows
$flat-shadow: 0 1px 3px rgba($shade-80, 0.25);
$rised-shadow: 0 0 15px rgba($shade-80, 0.15);


// Buttons
$btn-radius: 15px;


// Mixins
@mixin prefix ($property, $value) {
    #{$property}: #{$value};
    -o-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
    -moz-#{$property}: #{$value};
    -webkit-#{$property}: #{$value};
}

@mixin keyframes-prefixes ($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
}

@mixin media-xs () {
    @media (max-width: $media-small - 1) {
        @content;
    }
}

@mixin media-s () {
    @media (min-width: $media-small) and (max-width: $media-medium - 1) {
        @content;
    }
}

@mixin media-m () {
    @media (min-width: $media-medium) and (max-width: $media-large - 1) {
        @content;
    }
}

@mixin media-l () {
    @media (min-width: $media-large) {
        @content;
    }
}

@mixin wh ($size) {
    width: $size;
    height: $size;
}

@mixin center () {
    align-items: center;
    align-content: center;
    justify-content: center;
}

@mixin text-gradient ($params) {
  background: -webkit-linear-gradient($params);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}