@import '../../scss/variables';


.ExpandableRow {

&__container {

    border: 1px solid $shade-10;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: $c-white;
        border-color: $c-white;
        box-shadow: $rised-shadow;
    }

}

&__question {
    font-weight: $f-bold;
    width: calc(100% - 14pt - 10px);
}

&__iqon {

    fill: $shade-80;
    @include wh (14pt);
    margin-left: auto;

    &.active {
        @include prefix (transform, rotate(180deg));
    }
}

&__answer {

    span {

        display: block;
        color: $shade-70;
        font-size: 10pt;
        margin: 15px 0;
    
        &:nth-child(1) {
            margin-top: 30px;
        }
    
        strong {
            color: $c-black;
            font-weight: $f-black;
        }

    }

}

}