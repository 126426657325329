@import '../../scss/variables';


.BizPage {

&__title {

    h1 {
        font-size: calc(min(9.5vw, 45pt));
        text-align: center;
        margin: 15px 0;
    }

    &--subtitle {
        font-weight: $f-medium;
        font-size: calc(min(4vw, 16pt));
        text-align: center;
    }
    
}

&__cta {

    .btn {
        font-size: 12pt;
        font-weight: $f-black;
    }

    &-footer {
        color: $c-white;
        background-color: $c-black;
    }

}

&__percentages {

    &--item {

        border-left: 2px solid $shade-10;
        padding: 0 $padding-x;
        @include media-xs {
            border: none;
            margin-top: 40px;
        }

        &>div {
            padding: 0 30px 0 $padding-x;
            align-items: center;
            align-content: center;
        }

    }

    &>div:nth-child(1) {
        border: none;
        @include media-xs {
            margin-top: 0;
        }
    }

    &--title {
        
        display: inline-block;
        font-weight: $f-ultra;
        font-size: 50pt;
        border: 3px solid $c-black;
        border-radius: 20px;
        padding: 0 30px;
        margin: 0;

        span {
            font-size: 0.5em;
            margin-left: 5px;
            vertical-align: middle;
        }

    }

    &--description {
        color: $shade-70;
        font-size: 10pt;
        font-weight: $f-medium;
        text-align: center;
        line-height: 1.8;
        margin: 10px 0 0;
    }

}

&__features {

    &--risk {

        &-level,
        &-topics,
        &-people {

            img {
                width: 70%;
                margin: auto;
            }

            @include media-xs {

                order: 2;

                img {
                    width: 90%;
                    margin: auto;
                }

            }

        }

    }

}

&__feature {
    margin: 100px 0;
}

}