@import '../../scss/variables';


.Slot {

&__container {

    &:hover {

        .Slot__iqon {
            fill: $c-primary;
        }

        .Slot__number {
            background-color: $c-primary;
        }

    }

}

&__iqon {
    fill: $c-black;
    @include wh (40px)
}

&__number {
    display: flex;
    color: $c-white;
    font-size: 14pt;
    font-weight: $f-black;
    background-color: $c-black;
    border-radius: 100px;
    @include wh (50px);
    margin-bottom: 30px;
    @include center ();
}

&__title {
    font-size: 16pt;
    font-weight: $f-black;
}

}