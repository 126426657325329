* {

    box-sizing: border-box;

    &:not(input):not(textarea) {
        @include prefix (user-select, none);
    }
    
}  

div,
span,
button {
    box-sizing: border-box;
}

:focus:not(button):not(input) {
    outline: none;
}

body {
    // background-color: $shade-05;
    margin: 0;
}

.fullHeight {
    min-height: calc(100vh - 90px);
}

.hidden {
    display: none;
}

.showScroll {

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background-color: $shade-50;
        box-shadow: 0 0 1px rgba(255,255,255,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }

}


// Shadows interactivity
.shadow--grow {

    background-color: $c-white;
    box-shadow: $flat-shadow;
    @include prefix (transition, (transform 0.5s, box-shadow 0.5s));

    &:hover {
        @media (any-hover: hover) {
            box-shadow: $rised-shadow;
            @include prefix (transform, scale(1.03));
        }
    }

}


// Alignment & justification
.aFlex-start {align-content: flex-start; align-items: flex-start;}
.aCenter {align-content: center; align-items: center;}
.aFlex-end {align-content: flex-end; align-items: flex-end;}
.acFlex-start {align-content: flex-start;}
.acCenter {align-content: center;}
.acFlex-end {align-content: flex-end;}
.acSpace-between {align-content: space-between;}
.aiFlex-start {align-items: flex-start;}
.aiCenter {align-items: center;}
.aiFlex-end {align-items: flex-end;}
.jcFlex-start {justify-content: flex-start;}
.jcCenter {justify-content: center;}
.jcFlex-end {justify-content: flex-end;}
.jcSpace-between {justify-content: space-between;}
.jiFlex-start {justify-items: flex-start;}
.jiCenter {justify-items: center;}
.jiFlex-end {justify-items: flex-end;}


// Paddings & Margins
$padding-and-margin-pixels: (
    "Auto": auto,
    "-20": -20px,
    "-15": -15px,
    "-10": -10px,
    "-05": -5px,
    "00": 0px,
    "05": 5px,
    "10": 10px,
    "15": 15px,
    "20": 20px,
    "25": 25px,
    "30": 30px,
    "35": 35px,
    "40": 40px,
    "45": 45px,
    "50": 50px,
    "60": 60px,
    "70": 70px,
    "80": 80px,
    "90": 90px,
    "100": 100px,
    "150": 150px,
    "200": 200px,
    "250": 250px,
    "300": 300px,
    "350": 350px,
    "400": 400px,
    "450": 450px,
    "500": 500px
);

@each $number, $pixel in $padding-and-margin-pixels {
    .p#{$number} {padding: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .py#{$number} {padding-top: #{$pixel}; padding-bottom: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .px#{$number} {padding-right: #{$pixel}; padding-left: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .pt#{$number} {padding-top: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .pr#{$number} {padding-right: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .pb#{$number} {padding-bottom: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .pl#{$number} {padding-left: #{$pixel};}
}

@each $number, $pixel in $padding-and-margin-pixels {
    .m#{$number} {margin: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .my#{$number} {margin-top: #{$pixel}; margin-bottom: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .mx#{$number} {margin-right: #{$pixel}; margin-left: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .mt#{$number} {margin-top: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .mr#{$number} {margin-right: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .mb#{$number} {margin-bottom: #{$pixel};}
}
@each $number, $pixel in $padding-and-margin-pixels {
    .ml#{$number} {margin-left: #{$pixel};}
}