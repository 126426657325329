@import '../../scss/variables';


.RadioList {

&__item {

    font-size: 9pt;
    background-color: $c-white;
    border: 2px dotted $shade-10;
    border-radius: 50px;
    padding: 5px 15px;
    margin: 5px;
    cursor: pointer;

    &.active {
        color: $c-white;
        background-color: $c-primary;
        border: 2px solid $c-primary;
    }
    
    &:hover {
        border: 2px solid $c-black;
        // box-shadow: 0 0 10px rgba($c-mint, 0.25);
    }

}

}