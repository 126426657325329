@import '../../scss/variables';


.DoughnutGraph {

&__container {
    position: relative;
    @include wh (fit-content);
}

&__bg {
    fill: none;
    stroke: $shade-05;
}

&__fg {

    position: absolute;
    fill: none;
    stroke: $c-black;
    stroke-linecap: round;
    @include prefix (transform, rotate(-90deg));
    @include prefix (transition, (stroke-dasharray 1s, stroke 700ms));
    
    
    @each $name, $color in $colors-list {
        &.#{$name} {
            stroke: $color;
        }
    } 

}

&__number {

    position: absolute;
    display: flex;
    @include wh (100%);
    @include center ();
    top: 0;

    span {

        font-weight: $f-bold;

        &:nth-child(1) {
            margin-right: 3px;
        }

    }

}

}