@import '../../scss/variables';


.PricingCard {

&__container {
    background-color: $shade-02;
    border: 3px solid $shade-02;
    border-radius: 50px;
    @include prefix (transition, all 0.7s);

    &.active,
    &:hover {
        background-color: $c-white;
        border-color: $c-primary;
        box-shadow: 0 30px 15px -5px rgba($shade-80, 0.10);
        @include prefix (transform, translateY(-20px));
    }
}

&__title {
    font-size: 14pt;
    font-weight: $f-black;
}

&__price {
    
    height: 110px;

    &--amount {

        color: $c-primary;
        font-size: 24pt;
        font-weight: $f-ultra;
        text-align: center;

        sup {
            font-weight: $f-semibold;
            font-size: 0.5em;
        }

    }

    &--legal {
        color: $shade-70;
    }

}

&__description {

    flex-grow: 1;

    &--item {

        display: inline-block;
        color: $shade-70;
        font-size: 10pt;
        text-indent: calc(-5pt - 10px);
        width: calc(100% - 5pt - 10px);
        margin-left: calc(5pt + 10px);

        strong {
            color: $c-black;
            font-weight: $f-bold;
        }
        
        &::before {
            content: "";
            display: inline-block;
            background-color: $c-primary;
            border-radius: 100px;
            @include wh (4pt);
            margin-right: 10px;
            margin-bottom: 1pt;
        }

    }

}

}